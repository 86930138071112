//https://console.cloud.google.com/apis/credentials/oauthclient/946173785166-3v3crnfvse8uappshupprtd5qet16ah1.apps.googleusercontent.com?project=php-imap-260119

import React from 'react';

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


export class OneTapV2 extends React.Component {


    constructor(props) {
        console.log("props", props);
      super(props)
      window.customCallback = this.customCallback

      window.onLogin = props.onLogin;

  }
  
 


    customCallback(response) {
      console.log('ONE TAP version 2 ', response)

      const responsePayload = parseJwt(response.credential);

      console.log("responsePayload", responsePayload);
      console.log("ID: " + responsePayload.sub);
      console.log('Full Name: ' + responsePayload.name);
         console.log('Given Name: ' + responsePayload.given_name);
         console.log('Family Name: ' + responsePayload.family_name);
         console.log("Image URL: " + responsePayload.picture);
         console.log("Email: " + responsePayload.email);
    
        console.log("props2",this.props);
        if (typeof onLogin === 'function') {
            console.log("onLogin function");
            window.onLogin(responsePayload.sub, responsePayload.picture, responsePayload.email );
        }
        else {

            console.log("onLogin not function", window.onLogin);
        }
    }
  
    render() {
      return (
        <>
   <div id="g_id_onload"
     data-client_id="946173785166-3v3crnfvse8uappshupprtd5qet16ah1.apps.googleusercontent.com"
     data-context="signin"
     data-ux_mode="popup"
     data-callback="customCallback"
     data-auto_prompt="false">
</div>

<div className="g_id_signin"
     data-type="standard"
     data-shape="rectangular"
     data-theme="outline"
     data-text="signin_with"
     data-size="large"
     data-logo_alignment="left">
</div>
        </>
      )
    }
  }