import React, { HtmlHTMLAttributes } from 'react';
import { cronDescription, replaceAll } from '../objects';



interface CronState {
    id: number;
    cron: string | null;
    


    handleChange: ((id: number, name: string, value: string) => void);

    //    onChange:  (id: number, name: string, value: string) => {};
    //onSave:  (id: number) => {};

}

export interface Option {
    nr: number;
    text: string;
}

export class Cron extends React.Component<CronState> {


    constructor(props: CronState) {
        console.log("props", props);
        super(props)

    }

    public onChange = async (event: any) => {

        console.log('onChange', this.props.id, event);



    }

    public setCron(value: string) {

        this.props.handleChange(this.props.id, "cron", value);
    }

    public onClick = async (event: any) => {

        console.log('onChange', this.props.id, event);

        this.props.handleChange(this.props.id, "cron", event);

    }

    toggleDiv(element: any) {
        console.log("toggleDiv", element);
        if (element != null) {
            var s = element.target.nextElementSibling;
            if (s != null) {
                if (s.style.display == "block") {
                    console.log("21", s, s.style.display);
                    s.style.display = "none";
                }
                else {
                    console.log("22", s, s.style.display);
                    s.style.display = "block";
                }


            }
        }




        return false;
    }


    /*
    public onClick = async () => {
    
        console.log('onClick');
    
        this.props.onSave(this.props.id);
    
    }
    */

    private renderOptions(options: Option[], pattern: string) {

        const lastIndex = pattern.lastIndexOf('?'); console.log("renderOptions lastIndex", lastIndex);
        return (
            options.map((x: Option, index: number) =>

                <div key={"opt"+index} className='alertbtn' onClick={() => this.setCron(pattern.substring(0, lastIndex) + x.nr.toString() + pattern.substring(lastIndex + 1))}>{x.text}</div>

            )
        );

    }


    private Minutes(pattern: string) {
        console.log("Hours", pattern);

        const hours: Option[] = [{ nr: 0, text: ":00" }, { nr: 5, text: ":05" }, { nr: 10, text: ":10" }, { nr: 15, text: ":15" }, { nr: 20, text: ":20" }, { nr: 25, text: ":25" }, { nr: 30, text: ":30" }, { nr: 35, text: ":35" }, { nr: 40, text: ":40" }, { nr: 45, text: ":45" }, { nr: 50, text: ":50" }, { nr: 55, text: ":55" }
        ];

        return this.renderOptions(hours, pattern);
    }

    private Hours(pattern: string) {
        console.log("Hours", pattern);

        const hours: Option[] = [{ nr: 0, text: "12am" }, { nr: 1, text: "1am" }, { nr: 2, text: "2am" }, { nr: 3, text: "3am" }, { nr: 4, text: "4am" }, { nr: 5, text: "5am" }, { nr: 6, text: "6am" }, { nr: 7, text: "7am" }, { nr: 8, text: "8am" }, { nr: 9, text: "9am" }, { nr: 10, text: "10am" }, { nr: 11, text: "11am" }, { nr: 12, text: "12pm" },
        { nr: 13, text: "1pm" }, { nr: 14, text: "2pm" }, { nr: 15, text: "3pm" }, { nr: 16, text: "4pm" }, { nr: 17, text: "5pm" }, { nr: 18, text: "6pm" }, { nr: 19, text: "7pm" }, { nr: 20, text: "8pm" }, { nr: 21, text: "9pm" }, { nr: 22, text: "10pm" }, { nr: 23, text: "11pm" }
        ];

        return this.renderOptions(hours, pattern);
    }

    private Days(pattern: string) {
        console.log("Days", pattern);

        const days: Option[] = [{ nr: 1, text: "1st" }, { nr: 2, text: "2nd" }, { nr: 3, text: "3th" }, { nr: 4, text: "4th" }, { nr: 5, text: "5th" }, { nr: 6, text: "6th" }, { nr: 7, text: "7th" }, { nr: 8, text: "8th" }, { nr: 9, text: "9th" }, { nr: 10, text: "10th" }, { nr: 11, text: "11th" }, { nr: 12, text: "12th" }, { nr: 13, text: "13th" }, { nr: 14, text: "14th" }, { nr: 15, text: "15th" }, { nr: 16, text: "16th" }, { nr: 17, text: "17th" }, { nr: 18, text: "18th" }, { nr: 19, text: "19th" }, { nr: 20, text: "20th" }, { nr: 21, text: "21st" }, { nr: 22, text: "22nd" }, { nr: 23, text: "23th" }, { nr: 24, text: "24th" }, { nr: 25, text: "25th" }, { nr: 26, text: "26th" }, { nr: 27, text: "27th" }, { nr: 28, text: "28th" }, { nr: 29, text: "29th" }, { nr: 30, text: "30th" }, { nr: 31, text: "31st" }
        ];
        return this.renderOptions(days, pattern);
    }

    private Months(pattern: string) {
        console.log("Months", pattern);

        const days: Option[] = [{ nr: 1, text: "Jan" }, { nr: 2, text: "Feb" }, { nr: 3, text: "Mar" }, { nr: 4, text: "Apr" }, { nr: 5, text: "May" }, { nr: 6, text: "Jun" }, { nr: 7, text: "Jul" }, { nr: 8, text: "Aug" }, { nr: 9, text: "Sep" }, { nr: 10, text: "Oct" }, { nr: 11, text: "Nov" }, { nr: 12, text: "Dec" }
        ];
        return this.renderOptions(days, pattern);
    }

    private WeekDays(pattern: string) {
        console.log("WeekDays", pattern);
        const days: Option[] = [{ nr: 1, text: "Mon" }, { nr: 2, text: "Tue" }, { nr: 3, text: "Wed" }, { nr: 4, text: "Thu" }, { nr: 5, text: "Fri" }, { nr: 6, text: "Sat" }, { nr: 0, text: "Sun" }
        ];
        return this.renderOptions(days, pattern);
    }


    private showOptions(pattern: string) {

        const lastIndex = pattern.lastIndexOf('?'); console.log("showOptions lastIndex", lastIndex);

        switch (lastIndex) {
            case 0:
                return this.Minutes(pattern);
                break;
            case 2:
                return this.Hours(pattern);
                break;

            case 4:
                return this.Days(pattern);
                break;
            case 6:
                return this.Months(pattern);
                break;
            case 8:
                return this.WeekDays(pattern);
                break;

        }


    }
/*
    private cronDescription(value: string|null) {
        if (value != null && value.length > 0 && !(value.indexOf("?") >= 0)) {
            return cronstrue.toString(value, { verbose: true, throwExceptionOnParseError: true });
        }
        return "No alert";
    }
*/
    public render() {

        var d = new Date();
        var estDate = d.toLocaleString('en-US', { timeZone: 'America/New_York' });

        const realcron = (this.props.cron != null ? replaceAll(this.props.cron, '\\?','1') : "");
        
        console.log('realcron',realcron);


        return (
            <React.Fragment>
                {/*JSON.stringify(this.props)*/}
                <input type="text" className='form-control' value={cronDescription(realcron)} placeholder="No Alert" onClick={() => this.setCron("")} readOnly></input>
                {/*this.props.cron*/}
                {this.props.cron != null && this.props.cron == "" &&
                
                    <div className="row" style={{ margin: '0 5%' }} >
                         <div className='col-12'>
                            <div>Server time: {estDate}</div>
                        </div>
                        <div className='alertbtn' onClick={() => this.setCron("")}>No alert</div>
                        <div className='alertbtn' onClick={() => this.setCron("? ? * * * ")}>Once a day</div>
                        <div className='alertbtn' onClick={() => this.setCron("? ? * * ?")}>Once a week</div>
                        <div className='alertbtn' onClick={() => this.setCron("? ? ? * *")}>Once a month</div>
                        <div className='alertbtn' onClick={() => this.setCron("? ? ? ? *")}>Once</div>
                    </div>
                }

                {this.props.cron != null && this.props.cron != "" && this.props.cron.lastIndexOf("?") > -1 && //Once a day
                    <div className="row" style={{ margin: '0 0.2rem' }} >
                         <div className='col-12'>
                            <div>Server time: {estDate}</div>
                        </div>
                        {this.showOptions(this.props.cron)}
                    </div>
                }



            </React.Fragment>
        );
    }
};


