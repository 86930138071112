import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import * as TodoStore from '../store/Todo';
import { ListType, ItemType, ILocalStorage } from '../objects';
//import { Form, Field, } from 'react-final-form'; //https://final-form.org/docs/react-final-form/examples
import configureStore from '../store/configureStore';
import styles from './Todo.module.css';
import { OneTapV2 } from './OneTapV2 ';
import { saveState, loadState } from '../localStorage';
import { basename, relative } from 'path';
import debounce from 'lodash.debounce'; //npm i --save-dev @types/lodash.debounce
import { ApplicationState } from '../store';
import { NONAME } from 'dns';

import { JsonHubProtocol } from '@microsoft/signalr';
import { Cron } from './Cron';
import { cronDescription } from '../objects';
//import PushNotificationDemo from '../notifications/PushNotificationDemo';


// At runtime, Redux will merge together...
export type TodoProps =
  TodoStore.TodoState // ... state we've requested from the Redux store
  & typeof TodoStore.actionCreators // ... plus action creators we've requested
  & RouteComponentProps<{ user: string }>; // ... plus incoming routing parameters


const sleep = (ms: any) => new Promise(resolve => setTimeout(resolve, ms))



const required = (value: string) => (value ? undefined : 'Required')

const composeValidators = (...validators: any[]) => (value: string) =>
  validators.reduce((error, validator) => error || validator(value), undefined)


const isColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
}



class Todo extends React.PureComponent<TodoProps> {
  // This method is called when the component is first added to the document
  public componentDidMount() {

    console.log("componentDidMount", this.props.loginuser)

    if (this.props.loginuser != '') {
      console.log("componentDidMount 1");
      this.ensureDataFetched();
    }
    else {
      const state: ILocalStorage = loadState();
      if (state != null && state.user != '') {
        console.log("componentDidMount 10");
        this.props.login(state.user, state.image, state.email);
      }
      if (state != null && state.listguid != '') {
        //console.log("componentDidMount 11");
        this.props.onListSelect(state.listguid, state.listname, state.orderType);
      }
    }

  }

  // This method is called when the route parameters change
  public componentDidUpdate() {
    console.log("componentDidUpdate 2");
    this.ensureDataFetched();
  }
  private ensureDataFetched() {

    this.props.requestLists();
    this.props.requestItems(this.props.selectedList.guid);

  }


  public onLogin = async (id: string, image: string, email: string) => {
    //await sleep(300);
    console.log('onLogin');
    this.props.login(id, image, email);
    //this.ensureDataFetched();
  }

  public onLogout = async (id: string) => {
    //await sleep(300);

    const elem = document.getElementById(id);
    //console.log("aa"+elem.innerHTML+"aa");

    if (elem != null && elem.innerHTML.indexOf("Logout") > -1) {
      elem.innerHTML = ' Are you sure?';
      setTimeout(() => { elem.innerHTML = ' Logout'; }, 3000);
    }
    else {
      this.props.logout();
    }

  }


  //--------------------------------------------------

  public onItemEdit = async (id: number) => {
    console.log("onEdit", id);
    if (id > -1) {
      this.props.onItemEdit(id);
    }

  }


  public onItemChange = (id: number, property: string, event: any) => {

    const value: string = event.target.value;

    console.log("onItemChange", id, property, value);

    this.props.onItemChange(id, property, value);

    this.onItemSaveDebounced(id);

  }

  public onCronChange = (id: number, property: string, value: string) => {


    console.log("onCronChange", id, property, value);

    this.props.onItemChange(id, property, value);

    this.onItemSaveDebounced(id);

  }


  public onItemSave = async (id: number) => {
    //await sleep(300);
    console.log('onItemChangeFinal');

    this.props.onItemSave(id);

  }

  public onItemSaveDebounced = debounce((id: number) => { this.onItemSave(id) }, 30000);


  public onTryDelete = async (source: string, destination: string) => {

    console.log("onTryDelete", source, destination);

    const s = document.getElementById(source);
    if (s != null) {

      console.log("dp1", s.style.display);
      s.dataset.display = s.style.display;
      s.style.display = "none";
    }

    const d = document.getElementById(destination);
    if (d != null) {
      console.log("dp21", d.style.display);
      d.style.display = "flex";
      console.log("dp22", d.style.display);
    }

  }
  public onCloseDelete = async (source: string, destination: string) => {

    const s = document.getElementById(source);
    if (s != null) {
      var dp: string = '';
      if (s.dataset.display != null) dp = s.dataset.display;
      console.log("dp2", dp);
      s.style.display = dp;
    }

    const d = document.getElementById(destination);
    if (d != null) {
      d.style.display = "none";
    }
  }


  public onItemDelete = async (id: number) => {
    //await sleep(300);
    console.log("onItemDelete", id);
    this.props.onItemDelete(id);
  }

  public onItemAdd = async (index: number, priority: number) => {
    //await sleep(300);
    console.log('onAdd');

    this.props.onItemAdd(index, priority);

    //this.props.setIndex(-1,'','',0);
    /*    this.forceUpdate();
      */



  }



  public onSetPriority = async (id: number, newOrder: number) => {
    //await sleep(300);
    console.log('onSetOrder', id, newOrder);
    this.props.onSetPriority(id, newOrder);
    /*
    const elem = document.getElementById("footer"+index);
    //console.log("aa"+elem.innerHTML+"aa");

    if (elem!=null) {
      elem.style.display = 'none';
    }
    */
  }


  //--------------------------------------------------

  public onListAdd = async () => {
    //await sleep(300);
    console.log('onAddList');

    const b = document.getElementById('closeList');
    if (b != null) b.click();

    const a = document.getElementById('addListName') as HTMLInputElement;

    var name: string = "My New List";
    if (a != null) {
      name = a.value;
      a.value = "";
    }

    if (!(name != null && name.length > 0)) {
      name = 'List ' + (this.props.lists.length + 1)
    }

    this.props.onListAdd(name);



  }


  public onListDelete = async (guid: string) => {
    //await sleep(300);

    console.log("onListDelete", guid);
    this.props.onListDelete(guid);

  }




  public onListSelect = async (guid: string, name: string, orderType: number) => {
    //await sleep(300);
    console.log('onSelectList');

    const b = document.getElementById('closeList');
    if (b != null) b.click();

    this.props.onListSelect(guid, name, orderType);



  }

  public onListEdit = async () => {

    this.props.onListEdit();

  }


  public onListChange = async (event: any) => {
    //await sleep(300);
    console.log('onSaveList');

    const name: string = event.target.value;

    console.log('onSaveList', name);

    if (name != null && name.length >= 0) {
      console.log("SAVE!!!!!", name);
      this.props.onListChange(name);
      this.onListSaveDebounced();
    }
  }

  public onListSave = async () => {
    //await sleep(300);
    console.log('onSaveListFinal');

    this.props.saveListFinal();

  }


  public onListSaveDebounced = debounce(this.onListSave, 3000);


  //--------------------------------------------------


  public onShare = async () => {
    //await sleep(300);

    const elem = document.getElementById('share');
    //console.log("aa", id,  elem);

    if (elem != null) {
      if (elem.style.display == 'inline') {
        elem.style.display = 'none';
      }
      else {
        elem.style.display = 'inline';
      }


    }
  }

  public onShowHere = async () => {
    //await sleep(300);

    const elem = document.getElementById('here');
    //console.log("aa", id,  elem);

    if (elem != null) {
      if (elem.style.display == 'inline') {
        elem.style.display = 'none';
      }
      else {
        elem.style.display = 'inline';
      }


    }
  }


  public showHideList = async () => {
    this.props.showHideList();
  }

  public notifyMe() {
    if (!("Notification" in window)) {
      // Check if the browser supports notifications
      alert("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      console.log("notification 1");
      const notification = new Notification("Hi there!");
      // …
    } else if (Notification.permission !== "denied") {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === "granted") {
          const notification = new Notification("Hi there!");
          // …
        }
      });
    }


    // At last, if the user has denied notifications, and you
    // want to be respectful there is no need to bother them anymore.
  }


  public render() {



    console.log("render", this.props.loginuser, (this.props.loginuser == undefined), (this.props.loginuser == null), (this.props.loginuser == ''));

    //this.notifyMe();



    return (
      <>
        {/*
<PushNotificationDemo />
 */}

        <pre>
          {/*{JSON.stringify(this.props, null, '\t')}*/}

        </pre>
        {/* 
      loginuser: {this.props.loginuser}
      listguid: {this.props.listguid}
      listname: {this.props.listname}
*/}





        <React.Fragment>



          {/*<code>{JSON.stringify(this.props)}</code>*/}
          {/*email:{this.props.email}*/}
          <div className='row'>

            <h1 className="col-12 col-lg-6 mt-4">
              {this != null && this.props != null && this.props.image != null && this.props.image.length > 0 &&
                <img src={this.props.image} className="img-fluid" style={{ width: '64px', height: '64px', padding: 0 }} title={this.props.email} />
              }
              {process.env.REACT_APP_NAME}
              {this.props.loginuser != '' &&
                <i className="bi bi-gear float-end" onClick={this.showHideList}></i>
              }
            </h1>


            <p className=" mb-3">Never Forget</p>

          </div>
          {this.props.loginuser != '' && this.props.showLists &&

            <div className='row'>
              <div className='col-12 col-lg-6'>
                {this.props.lists.map((element: ListType, index: number) =>
                  <div className={'card ' + styles.card + ' ' + this.getColor(0,0, 4) + ' ' + styles.mb066} key={'list' + element.guid} >
                    <div className={'card-body ' + styles.cardbody}>
                      <div className="row">
                        <div className="col-12">
                          <div className="row">
                            <div className="col-10">
                              <p className="card-title" style={{ marginRight: '2rem' }}><a onClick={() => this.onListSelect(element.guid, element.name, element.orderType)}
                                dangerouslySetInnerHTML={{ __html: (element.linked == true ? '<i className="bi bi-share"></i> ' + element.name : element.name) }}></a></p>

                            </div>
                            <div className="col-2" id={'listrow' + index}>

                              {element.orderType == 0 &&
                                <i className="bi bi-sort-down float-end" onClick={() => this.props.onListSort(element.guid)}></i>
                              }
                              {element.orderType == 1 &&
                                <i className="bi bi-sort-numeric-down float-end" onClick={() => this.props.onListSort(element.guid)}></i>
                              }
                              {element.orderType == 2 &&
                                <i className="bi bi-sort-alpha-down float-end" onClick={() => this.props.onListSort(element.guid)}></i>
                              }



                              <i className="bi bi-trash float-end" onClick={() => this.onTryDelete('listrow' + index, 'deleteConfirmation' + index)} id={'removelist' + index} style={{ padding: '0 0.5rem', zIndex: 100 }}></i>
                            </div>

                          </div>
                        </div>

                        <div className='mt-2' style={{ display: 'none' }} id={'deleteConfirmation' + index}>
                          <div className={'col-6 ' + styles.center2 + ' ' + styles.close} onClick={() => this.onCloseDelete('listrow' + index, 'deleteConfirmation' + index)} >
                            <i className="bi bi-x-lg"></i> Cancel
                          </div>
                          <div className={'col-6 ' + styles.center2 + ' ' + styles.delete} onClick={() => this.onListDelete(element.guid)}>
                            <i className="bi bi-trash"></i> Delete
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                )}

              </div>
            </div>

          }

          {this.props.loginuser != '' && this.props.showLists &&

            <div className='row'>
              <div className='col-12 col-lg-6'>
                <div className={'card ' + styles.card + ' ' + this.getColor(0,0, 4) + ' ' + styles.mb066}>
                  <div className={'card-body ' + styles.cardbody}>
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-10">
                            <input autoFocus className="form-control" type="text" id="addListName" placeholder='New List Name or Key' maxLength={80} />

                          </div>
                          <div className="col-2">
                            <i className="bi bi-plus-square float-end" style={{ padding: '0.3rem 0.5rem', zIndex: 100 }} onClick={() => this.onListAdd()}></i>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>

          }



          {this.props.loginuser != '' && (!this.props.showLists) &&

            <>
              {this.renderTable()}
              <div className="row">
                {/*<div className='col-4 col-lg-2'>{this.props.loginuser != '' && <i className="bi bi-trash float-start" onClick={() => this.onDeleteAll('deleteall')} id={'deleteall'}> Delete All</i>}</div>*/}
                <div className='col-6 col-lg-3 text-center'>{this.props.loginuser != '' && <i className="bi bi-share float-start" onClick={() => this.onShare()}> Share</i>}</div>
                <div className='col-6 col-lg-3'>{this.props.loginuser != '' && <i className="bi bi-power float-end" onClick={() => this.onLogout('logout')} id={'logout'}> Logout</i>}</div>
              </div>
              <div className='row text-center' id='share' style={{ display: 'none' }}>
                <div className='col-12 col-lg-6 text-center'>
                  <p> Share this list with anyone you want, by sending them this key via text, email,...</p>
                  <p>
                    {this.props.selectedList.guid}
                  </p>
                  <p>
                    They need to enter the key as the name of a new list on their {process.env.REACT_APP_NAME} and add the list. The name you gave to the list will show on their {process.env.REACT_APP_NAME}.
                  </p>                    </div>
              </div>
            </>
          }


          {this.props.loginuser == '' &&
            <>
              <div className='col-12 col-lg-6'>
                <OneTapV2 onLogin={this.onLogin}></OneTapV2>
              </div>
              <div className='row mt-5'>
                <div className='col-12 col-lg-6'>
                  <p>Turn your mobile phone into your executive assistant. Organize your priorities. Make sure you don't forget small things, like replenishing the milk in the fridge. Use "{process.env.REACT_APP_NAME}" for all you want to remember to do, and record in separate lists that you can view from anywhere in just two clicks, and much more! Go <span onClick={this.onShowHere} className='danger'>here</span> for a bunch of ideas about making your mobile a great personal assistant, working for you for free 24x7x365.</p>

                  <div id="here" style={{ display: 'none' }}>
                    <h2 className="danger mt-4 mb-3">How does {process.env.REACT_APP_NAME} work?</h2>
                    <p>
                      <span onClick={this.onShowHere} ><b>{process.env.REACT_APP_NAME}</b></span> is your executive assistant. In the future it may do things for you, but for now it excels at just helping you with memorizing "to do" things.
                    </p>
                    <p>
                      <span onClick={this.onShowHere} ><b>{process.env.REACT_APP_NAME}</b></span> uses the colors of a traffic stop light, and lists, to help you prioritize the things that you want to do. Intuitively, Red is for <b>Shall Do's</b>, Yellow is for <b>Will Do's</b>, and Green is for <b>Done</b>.
                      A neutral Blue is for your <b>archived items</b> that you may want to promote to Red, Yellow or Green in a particular type of list. Use Blue for repeatable tasks, for instance."
                    </p>

                    <p>
                      When you start a new list, you get three default items: Red, Yellow and Green, that you can customize through simple editing, by clicking on them. Stop editing an item for a few seconds and the item saves itself and shows in your list. Add items by clicking on the + sign or move them into another color category by clicking on that color button. Click X to close the "Edit Mode" for an item and save it. Delete an item by clicking on the trash icon. That's it, for now!
                    </p>

                    <p>
                      <b>Use {process.env.REACT_APP_NAME} for your grocery list:</b>
                      <br />
                      Add items you want to buy on the color of your choice (Red for the "must buy"). While in the store, once you placed an item in the cart, you may check it out by moving it into another color (recommended: Green or Blue). Make sure to add just one {process.env.REACT_APP_NAME} item for every item you want to buy. If you have multiple places where you need to go, you can create a separate list for every place (e.g. Costco, Lowe's, Whole Foods).
                    </p>

                    <p>
                      <b>Use {process.env.REACT_APP_NAME} for your ToDo list:</b>
                      <br />
                      Use Red for Priorities, Yellow for Nice to do, Green for Done, Blue for repetitive tasks that you want to reactivate.
                    </p>

                    <p><b>Beta Testers:</b>
                      <br />
                      For helping us shape {process.env.REACT_APP_NAME} into a powerful personal assistant, you will enjoy it for free and without ads, for a lifetime. Did you find a bug? Can you propose an improvement? Click here and let us know. We will offer you other incentives as we go by, please stay tuned!
                    </p>

                  </div>

                  <p><a href={"mailto:" + process.env.REACT_APP_FEEDBACK}>Feedback</a></p>




                </div>
              </div>

            </>

          }



        </React.Fragment>





      </>
    );
  }


  private getColor(index: number, orderType: number, order: number): string {
    //console.log("getColor",order, order==1);


    switch (orderType) {
      case 0:
        //console.log(0);
        switch (order) {
          case 0:
            //console.log(0);
            return styles.color0;
            break;
          case 1:
            //console.log(1);
            return styles.color1;
            break;
          case 2:
            //console.log(2);
            return styles.color2;
          default:
            //console.log(3);
            return styles.color3;
        }
        break;
      case 1:
        return styles.colordefault;
        break;
      case 2:
        return (index%2==0) ? styles.colordefault : styles.colordefault2;
      default:
        //console.log(3);
        return styles.colordefault;
    }



  }


  public onClear = async (id: string) => {

    const elem = document.getElementById(id) as HTMLInputElement;
    //console.log("aa"+elem.innerHTML+"aa");

    if (elem != null) {
      elem.value = "";
    }

  }









  renderOptions(index: number, id: number, orderType: number, priority: number) {

    if (orderType == 0) {
      switch (priority) {
        case 0:
          return <>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color1} onClick={() => this.onSetPriority(id, 1)}>
              <i className="bi bi-box-arrow-in-down"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color2} onClick={() => this.onSetPriority(id, 2)}>
              <i className="bi bi-box-arrow-in-down"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color3} onClick={() => this.onSetPriority(id, 3)}>
              <i className="bi bi-box-arrow-in-down"></i>
            </div>
          </>;
        case 1:
          return <>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color0} onClick={() => this.onSetPriority(id, 0)}>
              <i className="bi bi-box-arrow-in-up"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color2} onClick={() => this.onSetPriority(id, 2)}>
              <i className="bi bi-box-arrow-in-down"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color3} onClick={() => this.onSetPriority(id, 3)}>
              <i className="bi bi-box-arrow-in-down"></i>
            </div>
          </>;
        case 2:
          return <>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color0} onClick={() => this.onSetPriority(id, 0)}>
              <i className="bi bi-box-arrow-in-up"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color1} onClick={() => this.onSetPriority(id, 1)}>
              <i className="bi bi-box-arrow-in-up"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color3} onClick={() => this.onSetPriority(id, 3)}>
              <i className="bi bi-box-arrow-in-down"></i>
            </div>
          </>;
        default:
          return <>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color0} onClick={() => this.onSetPriority(id, 0)}>
              <i className="bi bi-box-arrow-in-up"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color1} onClick={() => this.onSetPriority(id, 1)}>
              <i className="bi bi-box-arrow-in-up"></i>
            </div>
            <div className={'col-2 ' + styles.center2 + ' ' + styles.color2} onClick={() => this.onSetPriority(id, 2)}>
              <i className="bi bi-box-arrow-in-up"></i>
            </div>
          </>;
      }
    }

    if (orderType == 1) {

      return <>
        {priority > 0 &&
          <div className={'col-2 ' + styles.center2 + ' ' + styles.colordefault + ' ' + styles.border} onClick={() => this.onSetPriority(id, priority - 1)}>
            <i className="bi bi-box-arrow-in-up"></i>
          </div>
        }
        <div className={'col-2 ' + styles.center2 + ' ' + styles.colordefault + ' ' + styles.border} onClick={() => this.onSetPriority(id, priority + 1)}>
          <i className="bi bi-box-arrow-in-down"></i>
        </div>

      </>;
    }

    if (orderType > 1) {
      return <></>;
    }


  }



  private renderTable() {

    console.log("renderTable", this.props.loginuser);


    return (

      <>
        <div className="row">
          <div className='col-12 col-lg-6'>
            {this.props.editList}
            <h1>
              {this.props.editList != true &&
                <span onClick={() => this.onListEdit()} dangerouslySetInnerHTML={{ __html: (this.props.selectedList.linked == true ? '<i className="bi bi-share"></i> ' + this.props.selectedList.name : this.props.selectedList.name) }}></span>
              }

              {this.props.editList == true &&
                <div className="row">
                  <div className="col-10">
                    <input autoFocus type="text" className="form-control" id="saveListName" placeholder='List Name' value={this.props.selectedList.name} onChange={this.onListChange} maxLength={80} />
                  </div>
                  <div className={"col-2 " + styles.center2} onClick={this.onListSave} >
                    <i className="bi bi-save-fill"></i>
                  </div>
                </div>
              }

            </h1>

          </div>
          {/*
<div className='col-2 col-lg-1'>
{this.props.reminders.user!='' && 
        <h1><i className="bi bi-plus-square float-end" onClick={()=>this.onAdd(-1)}></i></h1>
}
    
</div>
*/}
        </div>


        {/*JSON.stringify(this.props.selectedList)*/}

        {this.props.data != null && this.props.data.length > 0 && this.props.data.map((result: ItemType, index: number) =>



          <div className={"row item" + result.id} key={'card' + result.id}>
            <div className='col-12 col-lg-6'>


              <div className={'card ' + styles.card + ' ' + this.getColor(index, this.props.selectedList.orderType, result.priority) + ' ' + styles.mb066}>

                <div className={'card-body ' + styles.cardbody}>
                  <div className="row">
                    <div className="col-12" >

                      {!result.isEdit &&
                        <div className='row'><div className='col-12'> {/*  onClick={() => this.onMenu(-1)} */}

                          <div className="card-text" >
                            {/*<span className='float-end ' style={{cursor: 'pointer', padding: '10px'}} onClick={()=> this.props.onOrderby(result.id)}>{index+1}</span>*/}
                            {this.props.selectedList.orderType == 1 &&
                              <span className='cursor-pointer float-end p-[10px]' style={{  }} >{result.priority}</span>
                            }
                            <span className='cursor-pointer' style={{  }} onClick={() => this.onItemEdit(result.id)}>
                              {result.title != null && result.title.length > 0 ? result.title : (result.description != null && result.description.length > 0 ? result.description : 'Nothing to remember?')}
                            </span>

                          </div>  {/*result.order*/}
                          <p className="card-text cursor-pointer" style={{  }} onClick={() => this.onItemEdit(result.id)}>
                            {result.title != null && result.title.length > 0 ? result.description : ""}
                          </p>{/* {result.objectDate} */}

                          {result.cron != null && result.cron.length > 0 &&
                            <p className='cursor-pointer text-end' style={{  }} onClick={() => this.onItemEdit(result.id)}>
                              <i style={{ marginRight: '6px' }} >{cronDescription(result.cron)} </i>
                              <i className="bi bi-bell-fill" ></i>
                            </p>
                          }
                        </div>
                        </div>
                      }
                      {result.isEdit &&
                        <><div className="row mb-2">
                          <div className="col-12">
                            <input type="text" autoFocus className="form-control" id={"title_" + result.id} name={"title_" + result.id} value={result.title} onChange={(e) => this.onItemChange(result.id, "title", e)} placeholder="Line 1" maxLength={280} />
                          </div>
                        </div><div className="row mb-2">
                            <div className="col-12">
                              <input type="text" className="form-control" id={"description_" + result.id} name={"description_" + result.id} value={result.description} onChange={(e) => this.onItemChange(result.id, "description", e)} placeholder="Line 2 (optional)" maxLength={280} />
                            </div>
                          </div>

                          <div className="row mb-2">
                            <div className="col-12">
                              <Cron cron={result.cron} id={result.id} handleChange={this.onCronChange} />
                            </div>
                          </div>
                          {/*JSON.stringify(result)*/}
                        </>

                      }

                      {result.isEdit &&

                        <>
                          <div className='row' style={{ margin: '0 0.2rem' }} id={'mainrow' + result.id}>

                            <div className={'col-2 ' + styles.center2 + ' ' + styles.delete} onClick={() => this.onTryDelete('mainrow' + result.id, 'deleteConfirmation' + result.id)} >
                              <i className="bi bi-trash"></i>
                            </div>




                            {this.renderOptions(result.id, result.id, this.props.selectedList.orderType, result.priority)}


                            <div className={'col-2 ' + styles.center2 + ' ' + styles.border} onClick={() => this.onItemAdd(index, result.priority)}>
                              <i className="bi bi-plus-square"></i>{/*result.orderby*/}
                            </div>


                            <div className={"col-2 " + styles.center2 + ' ' + styles.close} onClick={() => this.onItemSave(result.id)}>
                              <i className="bi bi-save"></i>
                            </div>


                          </div>

                          <div className='row' style={{ margin: '0 0.2rem', display: 'none' }} id={'deleteConfirmation' + result.id}>
                            <div className={'col-6 ' + styles.center2 + ' ' + styles.close} onClick={() => this.onCloseDelete('mainrow' + result.id, 'deleteConfirmation' + result.id)} >
                              <i className="bi bi-x-lg"></i> Cancel
                            </div>
                            <div className={'col-6 ' + styles.center2 + ' ' + styles.delete} onClick={() => this.onItemDelete(result.id)}>
                              <i className="bi bi-trash"></i> Delete
                            </div>
                          </div>


                          <div className='row' style={{ margin: '0 0.2rem', display: 'none' }} id={'alertConfirmation' + result.id}>

                            {/*JSON.stringify(result)*/}






                          </div>





                        </>
                      }
                    </div>

                    {/*<i className="bi bi-three-dots-vertical float-start" ></i>*/}

                  </div>



                </div>



              </div>

            </div>

          </div>



        )}

        {/*JSON.stringify(this.props.data, null, '\t')*/}
      </>
    );
  }


}



export default connect(
  (state: ApplicationState) => state.reminders, // Selects which state properties are merged into the component's props
  TodoStore.actionCreators // Selects which action creators are merged into the component's props
)(Todo as any); // eslint-disable-line @typescript-eslint/no-explicit-any
