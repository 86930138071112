import cronstrue from 'cronstrue'; //https://github.com/bradymholt/cronstrue


export interface ListType {
    //user: string;
    guid: string;
    name: string;
    linked: boolean;

    orderType: number;
}

export interface ItemType {
    id: number;
    //list_id: number;
    title: string,
    description: string;
    priority: number;
    objectDate: string;
    //orderby: number;
    cron: string;
    isEdit: boolean;
}

export interface ILocalStorage {
    user: string;
    image: string;
    email: string;
    listguid: string;
    listname: string;
    orderType: number;
}

export interface ILink {
    user: string;
    guid: string;
}

export function cronDescription(value: string | null) {
    console.log("cronDescription", value);
    try {
        if (value != null && value.length > 0 && !(value.indexOf("?") >= 0)) {
            return cronstrue.toString(value, { verbose: true, throwExceptionOnParseError: false });
        }
    } catch (error) {
        console.error(error);
    }
    return "No alert";
}


export function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(find, 'g'), replace);
}

