import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Todo from './components/Todo';


//import * as AppStore from './store/App';



import './custom.css'
import { ApplicationState } from './store';

export class App extends React.PureComponent<ApplicationState> {
    
    public render() {

        return (
            <Layout>
                <Route exact path='/' component={Todo} />
            </Layout>
        );
    }

}

export default connect(
    (state: ApplicationState) => state, // Selects which state properties are merged into the component's props
    null , // Selects which action creators are merged into the component's props
)(App); // eslint-disable-line @typescript-eslint/no-explicit-any
